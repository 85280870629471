import { useEffect } from 'react';
import { useQueryParam } from './useQueryParam';

export const useQueryParamEffect = (
	queryParam: string,
	effect: (result: string) => void,
	dependencies: any[] = []
) => {
	const queryParamValue = useQueryParam(queryParam);

	useEffect(() => {
		if (typeof queryParamValue === 'string') {
			effect(queryParamValue);
		}
	}, [...dependencies, queryParamValue]);
};
